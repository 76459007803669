.cropped-image-upload {
  img {
    background-color: lightgray;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: center;
    border-color: transparent;
  }

  input[type='file'] {
    display: none;
  }
}

_no_image {
}
