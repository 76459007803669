body {
  overflow-x: hidden;
}
.about-box {
  @include box($white, $gray-800);
  box-shadow: none;
  border-radius: 5px;
  margin-bottom: 20px;
  min-height: 180px;
  padding: 20px;
  width: 100%;

  .about-image {
    border-radius: 20px;
    height: 200px;
    margin: 20px;
    width: 100%;
  }

  .about-title {
    margin: 15px 0px;
  }

  .about-description {
    padding: 0px 10px;
  }
}

.about-box-aim {
  @include box($white, $gray-800);
  border-radius: 5px;
  box-shadow: none;
  margin-bottom: 20px;
  min-height: 100px;
  padding: 20px;
  width: 100%;

  .about-image {
    border-radius: 20px;
    height: 200px;
    width: 100%;
  }

  .about-title {
    margin: 15px 0px;
  }

  .about-description {
    padding: 0px 10px;
  }
}

.about-box-guide {
  @include box($white, $gray-800);
  box-shadow: none;
  border-radius: 0 0 5px 5px;
  border-top-style: none;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-color: lightblue;
  display: block;
  padding: 0 10px 10px 10px;
  min-height: 150px;
  width: 90%;

  p {
    margin: 0 10px 10px 10px;
    padding: 10px 10px 10px 10px;
  }

  .about-image {
    border-radius: 20px;
    height: 200px;
    width: 100%;
  }

  .about-description {
    padding: 0 10px;
  }
}

.about-box-guide-title {
  @include box($white, $gray-800);
  box-shadow: none;
  border-radius: 5px 5px 0px 0px;
  display: block;
  margin-bottom: 0;
  max-height: 50px;
  padding: 5px 0 5px 0;
  width: 90%;

  &.reliable {
    background-color: $sun-light;
  }
  &.resourceful {
    background-color: $sun-medium;
  }
  &.sustainable {
    background-color: $sun-dark;
  }
  &.entrepreneurial {
    background-color: $s-orange;
  }
  &.energized {
    background-color: #eb1515;
  }

  h4 {
    color: white;
    margin: 2px 10px 0px 10px;
  }

  i {
    font-size: 1em;
    margin: 5px;
  }

  .about-image {
    border-radius: 20px;
    height: 200px;
    width: 100%;
  }

  .about-title {
    margin: 15px 0px;
  }

  .about-description {
    padding: 0px 10px;
  }
}

.about-hero {
  background-image: url('../static/images/about/about-header.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 32vh;

  &.newsletter {
  background-image: url('../static/images/newsletters/top_banner2.jpg');
  }
}

.about-banner {
  background: #124491;
  display: block;
  height: 30vh;
  max-height: 500px;
  min-height: 400px;
  position: relative;
  width: 100%;
  z-index: -1;

  .about-believe-visual {
    background: url('../static/images/about/about-sun.jpg');
    background-position: left center;
    background-repeat: no-repeat;
    clip-path: ellipse(50% 150% at 0% 50%);
    height: 30vh;
    max-height: 500px;
    min-height: 400px;
    position: absolute;
    width: 100%;
  }

  .about-main-title {
    display: block;
    padding: 40px;
    height: 50vh;
    margin: none;
    max-height: 500px;
    min-height: 460px;
    position: relative;
  }

  p {
    margin: 10px;
    line-height: 1.5em;
  }
}

.about-worldmap-visual {
  background: url('../static/images/about/sp-worldmap-latest.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 500px;
}

.about-contact {
  text-align: center;
  width: 100%;
  p {
    margin: 10px 0px 5px 0px;
    padding: 0px 20px 0px 20px;
  }
}

.about-subtitle {
  font-size: 2.5rem;
  color: #124491;
  font-weight: 650;
}

.contact-image {
  border-radius: 50%;
  margin: 40px 20px 10px 20px;
  width: 220px;
}

.team-column {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px;
}

.team-image {
  border-radius: 50%;
  margin: 20px 10px;
  width: 220px;
  -webkit-box-shadow: 4px 6px 19px -10px rgba(52, 58, 64, 0.5);
  -moz-box-shadow: 4px 6px 19px -10px rgba(52, 58, 64, 0.5);
  box-shadow: 4px 6px 19px -10px rgba(52, 58, 64, 0.5);
}

.team-title {
  margin: 10px;
}

.team-flags {
  border-radius: 2px;
  margin-right: 5px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(52, 58, 64, 0.3);
  box-shadow: 0px 0px 5px 0px rgba(52, 58, 64, 0.3);
  width: 25px;
}

@media only screen and (max-width: 768px) {
  .contact-page,
  .about-contact,
  .contact-image {
    text-align: center;
  }
}

.edition-nr {
  background-color: #f9d5ca;
  border-radius: 15px;
  color: #030b45;
  float: left;
  margin: 3px 20px 35px 0px;
  max-width: 40px;
  min-width: 25px;
  padding: 0px 5px;
  text-align: center;
}

.worldmap-image {
  margin-top: -50px;
  overflow: hidden;
  padding: 0px;
  position: absolute;
  width: 45%;
  z-index: 1;
}

.what-we-do-section {
  background: #cbe0f3;
  margin-top: -5vh;
  padding-top: 10px;

  .what-we-do-visual {
    background-image: url('../static/images/about/about-what-we-do.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    height: auto;
    margin-bottom: 20px;
    min-height: 94%;
    width: 110%;
  }
}

.track-record-box {
  @include box($white, $gray-800);
  border-radius: 5px;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 15%);
  margin-top: -5vh;
  padding: 20px;

  p {
    padding: 0px;
  }

  h2 {
    background: linear-gradient(
      0deg,
      rgba(203, 224, 243, 1) 0%,
      rgba(203, 224, 243, 1) 50%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 1) 100%
    );
    display: inline;
    line-height: 5.2rem;
    padding: 5px;
  }
}

.track-record-section {
  p {
    background-color: $s-blue;
    border-radius: 3px;
    max-width: 200px;
    padding: 10px 0 10px 0;
  }
  img {
    min-width: 500px;
    position: inherit;
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .about-worldmap-visual {
    min-height: 200px;
  }

  .about-believe-visual {
    display: none;
  }

  .what-we-do-visual {
    display: none;
  }

  .track-record-box {
    p {
      display: flex;
      flex-wrap: wrap;
    }
    a {
      margin-bottom: 5px;
    }
  }
}
